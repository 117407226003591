import { useState } from 'react';
import Image from 'next/image';

interface RippleImageProps {
  src: string;
  alt: string;
  loading?: 'lazy' | 'eager';
  width?: number;
  height?: number;
  sizes?: string;
  className?: string;
}

const RippleImage = (props: RippleImageProps) => {
  const imageSource = props.src;
  const imageAltText = props.alt;
  const imageDimensions = {
    width: props.width,
    height: props.height,
  };
  const imageSizes = props.sizes;
  const loadingStrategy = props.loading;
  const customClassName = props.className ?? '';

  const [isLoading, setIsLoading] = useState(true);

  const isFill =
    imageDimensions.width === undefined || imageDimensions.height === undefined;
  const containerClassName = isFill
    ? 'absolute inset-0'
    : 'relative w-full h-full';

  return (
    <div
      className={containerClassName}
      style={
        !isFill
          ? { width: imageDimensions.width, height: imageDimensions.height }
          : undefined
      }
    >
      {isLoading && (
        <div className="absolute inset-0 overflow-hidden">
          <div className="h-full w-full animate-pulse bg-gray-100">
            <div className="absolute inset-0 -translate-x-full animate-[shine_1.5s_infinite] bg-gradient-to-r from-gray-200 via-white to-gray-200" />
          </div>
        </div>
      )}
      <div className="absolute inset-0">
        <Image
          src={imageSource}
          alt={imageAltText}
          sizes={imageSizes}
          loading={loadingStrategy}
          onLoadingComplete={() => setIsLoading(false)}
          {...(isFill
            ? {
                fill: true as const,
                className: `object-cover transition-opacity duration-300 ${
                  isLoading ? 'opacity-0' : 'opacity-100'
                } ${customClassName}`,
              }
            : {
                width: imageDimensions.width,
                height: imageDimensions.height,
                className: `h-full w-full object-cover transition-opacity duration-300 ${
                  isLoading ? 'opacity-0' : 'opacity-100'
                } ${customClassName}`,
              })}
        />
      </div>
    </div>
  );
};

export default RippleImage;
