import { useContext, useEffect, useState } from 'react';

import {
  ArrowRightIcon,
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import cookies from 'js-cookie';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';

import { checkoutSession } from '../api/external/Payment';
import { getProductList } from '../api/external/Products';
import { Button } from '../components/Button';
import Loader from '../components/Loader';
import { TypeWriter } from '../components/molecules/TypeWriterAnimation';
import BlogPostCarousel from '../components/organisms/BlogPostCarousel';
import PremiumIcon from '../components/PremiumIcon';
import PricingTierCard from '../components/PricingTierCard';
import SocialMediaLinks from '../components/SocialMediaLinks';
import { landingToolCards, landingTypedWords } from '../constants/landing';
import { currencyMap, routes } from '../constants/routes';
import { testimonials } from '../constants/testimonials';
import { Product } from '../types/product';
import { signInUser } from '../utils/auth.utils';
import { EVENTS, trackEvent } from '../utils/tracking';
import { Portal } from '../enums/portal.enum';
import PortalContext from '../context/portal/context';
import RippleImage from '../components/RippleImage';

function Landing() {
  const { data: session } = useSession();
  const router = useRouter();
  const { mutateAsync } = checkoutSession({
    onSuccess: (data: any) => {
      router.push(data.redirectUrl);
    },
  });
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [currencyLoaded, setCurrencyLoaded] = useState(false);
  const country = cookies.get('country');
  const currency = currencyMap?.[country] || 'USD';
  const { data: tiers, isLoading } = getProductList(currency);
  const { setSelectedPortal } = useContext(PortalContext);
  const pageRoutes = [
    {
      label: 'Features',
      path: '#features',
    },
    {
      label: 'Pricing',
      path: '#pricing',
    },
    {
      label: 'Partner With Us',
      path: '#partner',
    },
  ];

  const educatorsCount = process.env.LP_EDUCATORS_COUNT || '400 Thousand';
  const hoursCount = process.env.LP_HOURS_COUNT || '6 Million';
  const createdContentsCounts = process.env.LP_CONTENTS_COUNT || '3 Million';

  useEffect(() => {
    if (selectedPlan) {
      mutateAsync(selectedPlan);
      setSelectedPlan(null);
    }
  }, [selectedPlan, mutateAsync]);

  const handleSelectPlan = (tier: Product) => {
    trackEvent(EVENTS.PLAN_SELECTED, {
      product: tier,
    });
    if (!session) {
      signInUser();

      return;
    }

    setSelectedPlan(tier.id);
  };

  useEffect(() => {
    if (currency !== null && currency !== undefined) {
      setCurrencyLoaded(true);
    }
  }, [currency]);

  const renderTestimonial = (testimonial, index) => {
    return (
      <div
        key={index}
        className="rounded-lg border border-[#DDEDFE] bg-[#EDF7FE] p-3 text-lg text-[#374151] md:p-5 lg:p-10"
      >
        <div>{testimonial.body}</div>
        <div className="mt-6 flex items-center">
          <div
            className={`aspect-square h-16 w-16 rounded-full bg-cover bg-center`}
            style={{
              backgroundImage: `url('${
                process.env.NEXT_PUBLIC_GOOGLE_CDN + testimonial.image
              }')`,
            }}
          ></div>
          <div className="ml-4">
            <p className="font-semibold text-[#111827]">{testimonial.name}</p>
            <p>{testimonial.position}</p>
          </div>
        </div>
      </div>
    );
  };

  const handleGoToApp = () => {
    const { query } = router;
    const queryParams = new URLSearchParams(
      query as Record<string, string>,
    ).toString();
    const country = cookies.get('country');
    const isLocal = country === 'PH';
    const redirectPath = isLocal ? routes.MAIN : routes.UNIT_PLAN;
    setSelectedPortal(isLocal ? Portal.PHILIPPINES : Portal.INTERNATIONAL);
    router.push(`${redirectPath}/?${queryParams}`);
  };

  const mapTestimonials = (testimonials: any[]) => {
    return testimonials.map((testimonial, index) =>
      renderTestimonial(testimonial, index),
    );
  };

  const renderNavbarOptions = () =>
    pageRoutes.map((route, index) => (
      <div key={index}>
        <a href={route.path}>
          <p>{route.label}</p>
        </a>
      </div>
    ));

  const ToolCard = ({ tool }: { tool: any }) => (
    <div
      className="rounded-lg p-4 pb-5 text-white lg:px-10 lg:pt-8"
      style={{ background: tool.color }}
    >
      <div className="flex items-center">
        <h3 className="text-xl font-semibold md:text-3xl">{tool.title}</h3>
        {tool.label && (
          <span
            className="text-white-400 ml-2 inline-block rounded-full px-2 py-1 text-xs font-semibold"
            style={{
              borderRadius: '100px',
              background:
                'linear-gradient(110deg, #EE7E2D -6.15%, #FFD88E 115.1%)',
            }}
          >
            {tool.label}
          </span>
        )}
      </div>
      <div className="text-lg text-white md:text-2xl">
        <p className="mt-2 pt-2">{tool.description}</p>
      </div>
    </div>
  );

  const LessonPlanCard = ({
    title,
    description,
    imageUrl,
  }: {
    title: string;
    description: string;
    imageUrl: string;
  }) => {
    return (
      <div className="mx-auto grid grid-rows-2 rounded-lg bg-white shadow-lg md:max-w-2xl">
        <div
          className="flex flex-col items-center justify-center space-y-4 rounded-t-lg p-6 md:p-8"
          style={{
            backgroundImage: `url(${'/images/mesh-gradient_2.png'})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div>
            <Image
              alt={title}
              className="mx-auto w-80 md:w-auto"
              height={500}
              src={imageUrl}
              width={500}
            />
          </div>
        </div>
        <div className="flex flex-col items-center justify-center space-y-4 rounded-b-lg bg-white p-8 pt-4 md:pt-0">
          <h2 className="mt-4 text-center text-3xl font-semibold">{title}</h2>
          <p className="text-center text-2xl text-gray-600 lg:mt-2">
            {description}
          </p>
        </div>
      </div>
    );
  };

  const Logo = () => (
    <div className="flex">
      <Image alt="logo" height={48} src="/images/logo.svg" width={48} />
      <div className="ml-3 flex flex-col justify-center font-bold md:text-xl">
        <p className="leading-tight">Lesson</p>
        <p className="leading-tight">Planner</p>
      </div>
    </div>
  );

  return (
    <div className="flex w-full flex-col items-center">
      <div className="relative flex w-full flex-col items-center justify-center">
        <div className="absolute right-0 -z-10 h-full w-full bg-[url('/images/decoration-1.svg')] opacity-30 md:w-[365px] md:opacity-100"></div>
        <div className="absolute left-0 -z-10 h-full w-full bg-[url('/images/decoration-1.svg')] opacity-30 md:w-[365px] md:opacity-100"></div>

        <div className="mt-8 flex w-full flex-row justify-between rounded-xl bg-[#EDF7FE] p-5 md:w-11/12 md:flex-row">
          <Logo />
          <div className="mt-4 ml-auto md:hidden">
            <button
              className="focus:outline-none"
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            >
              {isMobileMenuOpen ? (
                <XMarkIcon className="h-6 w-6" />
              ) : (
                <Bars3Icon className="h-6 w-6" />
              )}
            </button>
          </div>

          <div className="mt-4 hidden flex-row flex-wrap items-center justify-center gap-4 md:mt-0 md:flex md:justify-start md:gap-8">
            {renderNavbarOptions()}
            <div className="mt-0 w-full text-center md:w-auto">
              <Button
                className="rounded-lg bg-[#387DFD] px-5 py-3 text-white md:mt-0"
                type="button"
                onClick={handleGoToApp}
              >
                Use the App
              </Button>
            </div>
          </div>
        </div>
        {isMobileMenuOpen && (
          <div className="mt-4 flex w-full flex-col items-center justify-center gap-4 rounded-lg bg-[#EDF7FE] p-4 shadow-md md:hidden">
            {renderNavbarOptions()}
            <div className="mt-0 w-full text-center md:w-auto">
              <Button
                className="rounded-lg bg-[#387DFD] px-5 py-3 text-white md:mt-0"
                type="button"
                onClick={handleGoToApp}
              >
                Use the App
              </Button>
            </div>
          </div>
        )}

        <div className="mt-10 flex flex-col items-center justify-center px-4 text-center lg:w-[820px]">
          <p className="px-4 text-left text-4xl font-bold leading-tight text-[#111827] sm:text-6xl md:text-[90px]">
            It all starts with a good <TypeWriter words={landingTypedWords} />
          </p>
          <p className="mt-6 text-lg md:text-2xl">
            At Lesson Planner, we simplify lesson planning for teachers. Our AI
            tools help free up your time and energy to focus on what matters
            most – educating your students.
          </p>
        </div>
        <div className="mt-8 flex gap-4 text-xl font-semibold leading-none">
          <div className="relative">
            <div className="absolute inset-0 animate-pulse rounded-lg p-1 ring ring-offset-2 ring-offset-blue-500"></div>
            <Button
              className="relative rounded-lg bg-[#387DFD] py-5 px-8 text-white"
              onClick={handleGoToApp}
            >
              Use the App
            </Button>
          </div>
        </div>

        <div className="relative mt-10 h-[380px] w-full md:h-[750px]">
          <div className="absolute top-0 flex w-full justify-center">
            <Image
              alt="landing image"
              height={900}
              width={1280}
              src={
                process.env.NEXT_PUBLIC_GOOGLE_CDN +
                '/images/landing/webp/lp-image-3.webp'
              }
              loading="lazy"
            />
          </div>
        </div>
      </div>
      <div className="relative flex w-full flex-col bg-[url('/images/mesh-gradient_1.svg')] bg-cover bg-bottom bg-no-repeat px-4 py-12 md:flex-row md:py-[104px] lg:px-[160px]">
        <div className="relative z-10 flex w-full flex-col items-center justify-center text-center text-white lg:items-start lg:justify-start lg:text-left">
          <p className="text-2xl">Trusted by Educators</p>
          <p className="mt-4 text-3xl font-bold leading-snug md:text-4xl">
            Join the Growing Community <br />
            of Teachers using Lesson Planner
          </p>
          <p className="mt-10 text-5xl font-bold leading-none md:text-[80px]">
            {educatorsCount}
          </p>
          <div className="mt-2 text-xl md:text-3xl">
            Empowered educators using Lesson Planner
            <div className="relative hidden md:flex">
              <Image
                alt=""
                className="absolute top-2 right-1/4 md:-left-5"
                height={8}
                loading="lazy"
                src="/images/landing/underline-1.svg"
                width={331}
              />
            </div>
          </div>

          <p className="mt-10 text-5xl font-bold leading-none md:text-[80px]">
            {hoursCount}
          </p>
          <div className="mt-2 text-xl md:text-3xl">Hours saved</div>

          <p className="mt-14 text-5xl font-bold leading-none md:text-[80px]">
            {createdContentsCounts}
          </p>
          <div className="mt-2 text-xl md:text-3xl">
            Engaging and impactful learning materials
            <div className="relative hidden h-[25px] md:flex md:w-[238px]">
              <Image
                alt=""
                className="absolute top-2"
                height={25}
                src="/images/landing/underline-2.svg"
                width={238}
              />
            </div>
          </div>
        </div>
        <div className="absolute inset-0 flex items-center justify-center overflow-hidden">
          <img
            alt="World Map"
            className="h-full w-full object-contain opacity-25"
            height={887}
            width={650}
            src={
              process.env.NEXT_PUBLIC_LANDING_MAP ||
              process.env.NEXT_PUBLIC_GOOGLE_CDN +
                '/images/landing/world-map-no-bg.svg'
            }
          />
        </div>
      </div>
      <div className="w-full  bg-gradient-to-b from-[#a9efff] to-white p-4 md:py-[100px] lg:px-[100px]">
        <div className="mb-12 text-center">
          <p className="pt-5 text-xl font-semibold text-[#387DFD] md:text-3xl">
            Recent Events
          </p>
          <p className="text-4xl font-extrabold md:text-6xl">
            Here&apos;s What&apos;s Happening In <br />
            Lesson Planner
          </p>
        </div>
        <BlogPostCarousel />
      </div>
      <div className="relative w-full py-8 px-4 md:py-[100px] md:px-4">
        <div className="text-center">
          <p className="pt-5 text-xl font-semibold text-[#387DFD] md:text-2xl">
            User Testimonials
          </p>
          <p className="text-4xl font-extrabold md:text-6xl">
            Here&apos;s What Your Fellow Teachers Have to Say
          </p>
        </div>
        <div className="mt-14 flex w-full flex-col gap-2 md:gap-4 lg:flex-row lg:gap-8">
          {/* <div className="flex w-full mt-14"> */}
          <div className="mb-0 flex flex-col gap-2 md:mx-auto md:w-2/3 md:gap-4 lg:mb-8 lg:w-1/3 lg:gap-8">
            {mapTestimonials(testimonials.slice(0, 2))}
          </div>
          <div className="mb-0 flex flex-col gap-2 md:mx-auto md:w-2/3 md:gap-4 lg:mb-8 lg:w-1/3 lg:gap-8">
            {mapTestimonials(testimonials.slice(2, 3))}
          </div>
          <div className="hidden flex-col gap-2 md:mx-auto md:flex md:w-2/3 md:gap-4 lg:w-1/3 lg:gap-8">
            {mapTestimonials(testimonials.slice(3, 5))}
          </div>
        </div>
      </div>
      <div className="flex w-full flex-col items-center bg-gradient-to-r from-[#FD6738] via-[#FD6738] to-[#FF54F8] py-8 px-4 sm:px-8 md:px-16 lg:px-[160px]">
        <div className="w-full w-auto text-center text-white">
          <p className="mt-4 text-4xl font-extrabold md:text-7xl">
            How to generate a Lesson Plan
          </p>
          <p className="mt-4 mb-6 px-2 text-lg sm:px-4 sm:text-base md:px-16 md:text-2xl">
            New to Lesson Planner? Here&apos;s a quick tutorial on crafting your
            next lesson plan with our tools!
          </p>
        </div>
        <div
          className="relative my-8 w-full overflow-hidden rounded-xl"
          style={{ paddingTop: '56.25%' }}
        >
          <iframe
            allowFullScreen
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            className="absolute top-0 left-0 h-full w-full"
            src="https://www.youtube.com/embed/RG5sC4f1DNM?autoplay=1&mute=1&loop=1&playlist=RG5sC4f1DNM"
          ></iframe>
        </div>
        <Button
          className="flex items-center rounded-lg bg-white px-3 py-2 text-sm sm:px-4 sm:py-3 sm:text-base md:mb-14 md:mt-4 md:px-5 md:py-4 md:text-xl"
          onClick={handleGoToApp}
        >
          <span className="flex items-center bg-gradient-to-r from-[#FD6738] to-[#FF54F8] bg-clip-text font-medium text-transparent">
            Generate One Now
            <ArrowRightIcon className="ml-2 h-4 w-4 text-[#FD6738] sm:h-5 sm:w-5 md:h-6 md:w-6" />{' '}
          </span>
        </Button>
      </div>
      <div
        className="bg-[#EDF7FE] p-5 py-12 px-4 sm:px-8 md:px-14 lg:px-[160px]"
        id="features"
      >
        <div className="container mx-auto text-left">
          <p className="pt-5 text-xl font-semibold text-[#387DFD] md:text-2xl">
            Empower Your Teaching
          </p>
          <p className="pt-3 text-4xl font-extrabold md:text-6xl">
            Explore our Tools
          </p>
          <p className="mt-2 w-full pt-3 pb-3 text-lg text-gray-600 sm:w-2/3 md:text-2xl">
            Discover easy-to-use tools that make lesson planning a breeze and
            help you create engaging learning experiences for your students.
          </p>
        </div>
        <div className="mt-10 grid grid-cols-1 gap-4 pb-5 md:grid-cols-4">
          {landingToolCards.map((tool, index) => (
            <ToolCard key={index} tool={tool} />
          ))}
        </div>
      </div>

      <div className="relative w-full px-4 pt-[100px] sm:px-8 md:px-14 lg:px-40">
        <div className="absolute top-0 right-0 -z-10 h-full w-full bg-[linear-gradient(to_bottom,rgba(255,255,255,0),rgba(255,255,255,0),rgba(255,255,255,0.8)),url('/images/decoration-2.svg')] opacity-30 md:w-[365px] md:opacity-100"></div>
        <div className="absolute top-0 left-0 -z-10 h-full w-full bg-[linear-gradient(to_bottom,rgba(255,255,255,0),rgba(255,255,255,0),rgba(255,255,255,0.8)),url('/images/decoration-2.svg')] opacity-30 md:w-[365px] md:opacity-100"></div>
        <div className="text-center">
          <p className="text-xl font-semibold text-[#387DFD] md:text-2xl">
            Effortless Lesson Planning
          </p>
          <p className="pt-3 text-4xl font-extrabold md:text-7xl">
            Why You&apos;ll Love Lesson Planner
          </p>
          <p className="mt-2 pt-3 pb-3 text-lg text-gray-600 md:text-2xl">
            Effortlessly align with DepEd standards and access versatile <br />
            lesson plan formats with Lesson Planner.
          </p>
        </div>
        <div className="mt-10 grid grid-cols-1 gap-4 px-4 pb-5 md:grid-cols-2 md:px-12">
          <LessonPlanCard
            description="Choose from popular models like the 4As, 5Es, or 7Es to suit your teaching style and objectives."
            imageUrl="/images/landing/lesson-format.png" // Update the path as needed
            title="Educator-Focused Lesson Plan Formats"
          />
          <LessonPlanCard
            description="Stay ahead of the curve with the latest DepEd initiatives; seamlessly integrate the Matatag curriculum and CatchUp Friday into your classroom through modern technology."
            imageUrl="/images/matatag-logo.svg"
            title="Continuously Up To Date"
          />
        </div>

        {/* Temporarily hidden */}
        {/* <div
          className="grid max-w-full grid-rows-2 mx-auto bg-white rounded-lg shadow-lg"
          style={{ gridTemplateRows: '70% 30%' }}
        >
          <div
            className="flex flex-col items-center justify-center p-6 space-y-4 rounded-t-lg md:p-8"
            style={{
              backgroundImage: `url(${'/images/mesh-gradient_2.png'})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <div>
              <div className="grid max-w-full grid-cols-1 gap-4 p-2 mx-auto md:gap-8 md:p-8">
                <div className="z-10 flex justify-center">
                  <div
                    className="flex items-center justify-center rounded-lg p-0.5"
                    style={{
                      backgroundImage:
                        'linear-gradient(45deg, #F76B1C 0%, #FF54F8 100%)',
                    }}
                  >
                    <div className="flex flex-col items-start p-4 transform scale-100 bg-white rounded-lg">
                      <h4 className="text-lg font-semibold">
                        Catch-Up Fridays
                      </h4>
                      <p className="mt-2 text-sm text-gray-600">
                        Create engaging activities. Strengthen skills through{' '}
                        <br />
                        Values, Health, and Peace Education integration.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col justify-center gap-4 md:flex-row md:justify-between">
                  <div
                    className="flex items-center justify-center rounded-lg p-0.5"
                    style={{
                      backgroundImage:
                        'linear-gradient(45deg, #f3c6f1 0%, #a3d8f4 100%)',
                    }}
                  >
                    <div className="flex flex-col items-start w-full p-4 transform scale-100 bg-white rounded-lg">
                      <h4 className="text-lg font-semibold">Analogy</h4>
                      <p className="mt-2 text-sm text-gray-600">
                        Craft custom analogies to enhance teaching. <br />
                        Tailor them to your text or context.
                      </p>
                    </div>
                  </div>
                  <div
                    className="flex items-center justify-center rounded-lg p-0.5"
                    style={{
                      backgroundImage:
                        'linear-gradient(45deg, #b3c100 0%, #6ee7b7 100%)',
                    }}
                  >
                    <div className="flex flex-col items-start p-4 transform scale-100 bg-white rounded-lg">
                      <h4 className="text-lg font-semibold">
                        Learning Across Curriculum
                      </h4>
                      <p className="mt-2 text-sm text-gray-600">
                        Enrich lessons with cross-curricular concepts. <br />
                        Foster meaningful connections for students.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center p-2 space-y-1 bg-white rounded-b-lg">
            <h3 className="text-lg font-semibold">
              More Offerings to Help Teachers Productivity
            </h3>
            <p className="mt-2 text-center text-gray-600 text-md">
              Boost productivity with features like Catch Up Fridays, Summative
              Tests, <br /> and more, all designed to support educators in the
              Philippines.
            </p>
          </div>
        </div> */}

        <div className="w-full pt-20" id="pricing">
          <div className="text-center">
            <p className="text-xl font-semibold text-[#387DFD] md:text-2xl">
              Pricing
            </p>
          </div>
          <h2 className="p-3 text-center text-4xl font-bold md:text-6xl">
            Find the perfect plan for your needs
          </h2>
          <div className="mt-8 flex flex-col flex-wrap justify-center gap-4 pl-8 pr-8 md:flex-row md:pl-0 md:pr-0">
            {!currencyLoaded || isLoading ? (
              <Loader />
            ) : (
              tiers
                ?.slice(0, 4)
                .map((tier) => (
                  <PricingTierCard
                    key={tier.id}
                    currency={currency}
                    handleSelectPlan={handleSelectPlan}
                    tier={tier}
                  />
                ))
            )}
          </div>
          <div className="flex w-full justify-center px-6 sm:px-0">
            <div className="mt-10 flex w-fit flex-col justify-center italic text-gray-500">
              <label className="relative">
                <strong>Note: </strong>
                Premium credits are paid credits that are used to access any
                feature in Lesson Planner.
                <br />
                Premium features (marked with the crown icon
                <PremiumIcon className="inline-block h-5 fill-[#FFB743]" />)
                will consume different numbers of credits.
                <br />
                For example:
                <ul className="list-disc pl-5">
                  <li>1 Summative test will use 4 premium credits.</li>
                  <li>1 Daily lesson log will use up to 5 premium credits.</li>
                </ul>
                When you top up, make sure to choose the option that gives you
                enough premium credits for your needs.
              </label>
            </div>
          </div>
        </div>
      </div>

      <div
        className="w-full bg-gradient-to-b from-white to-[#DDEDFE] px-4 pt-24 pb-5 sm:px-8 md:px-24 md:pb-12 lg:px-[160px]"
        id="partner"
      >
        <div className="xl:px-50 relative mx-auto mb-20 max-w-[1280px] rounded-2xl bg-[url('/images/floaties.svg'),linear-gradient(to_right,rgba(56,125,253,.9),rgba(84,223,255,1))] bg-cover px-4 py-24 text-center sm:px-8 md:px-24">
          <h1 className="mb-12 text-4xl font-bold text-white md:text-6xl">
            Ready to join the thousands of educators using Lesson Planner?
          </h1>
          <p className="mb-16 text-xl text-white sm:text-base md:text-3xl">
            Transform your teaching experience with our tools designed to
            streamline your lesson planning. <br />
            Try the app or explore partnership opportunities with us today!
          </p>
          <div className="space-x-2 sm:space-x-5">
            <Button
              className="text-md rounded-md bg-white px-3 py-2 font-semibold text-blue-500 sm:px-6 sm:py-3 sm:text-xl"
              onClick={handleGoToApp}
            >
              Use the App
            </Button>
            <Button
              className="text-md rounded-md border-2 border-white bg-transparent px-3 py-2 font-semibold text-white sm:px-6 sm:py-3 sm:text-xl"
              onClick={() => router.push(routes.SCHOOL_CONTACT)}
            >
              Partner With Us →
            </Button>
          </div>
        </div>
      </div>
      <div className="w-full bg-[#DDEDFE] md:px-[160px]">
        <div className="mb-20 flex w-full flex-col items-center md:flex-row">
          <div className="w-full p-5 px-8 text-left md:w-1/2 md:p-0 md:px-0">
            <div className="flex flex-col text-left md:flex-row md:items-start">
              <Logo />
            </div>
            <p className="mt-8 mb-2 text-xl">
              Empowering educators one lesson plan at a time
            </p>
            <div className="mt-10 flex md:justify-start">
              <SocialMediaLinks />
            </div>
          </div>
          <div className="flex w-full flex-col items-start pt-3 pl-8 text-xl text-[#111827] md:w-1/2 md:flex-row md:p-0 md:pl-0">
            <div className="w-full text-left md:w-1/2">
              <h3 className="font-bold">Tools</h3>
              <ul className="mt-2">
                <li className="mt-5">
                  <Link href="/lesson-plan">Lesson Plan</Link>
                </li>
                <li className="mt-5">
                  <Link href="/summative-test">Summative Test</Link>
                </li>
                <li className="mt-5">
                  <Link href="/elif">ELI5</Link>
                </li>
                <li className="mt-5">
                  <Link href="/energizer">Energizer</Link>
                </li>
                <li className="mt-5">
                  <Link href="/topic-explorer">Topic Explorer</Link>
                </li>
              </ul>
            </div>
            <div className="w-full pt-16 text-left md:w-1/2 md:pt-0">
              <h3 className="font-bold">Company</h3>
              <ul className="mt-2">
                <li className="mt-5">
                  <Link href="https://www.facebook.com/lessonplannerph">
                    Support
                  </Link>
                </li>
                <li className="mt-5">
                  <Link href="/pricing">Pricing</Link>
                </li>
                <li className="mt-5">
                  <Link href="/pricing#faqs">FAQs</Link>
                </li>
                <li className="mt-5">
                  <Link href="/school-contact">Partnerships</Link>
                </li>
                <li className="mt-5">
                  <Link href="/school-contact">Contact Us</Link>
                </li>
                <li className="mt-5">
                  <Link href="/privacy-policy">Privacy Policy</Link>
                </li>
                <li className="mt-5">
                  <Link href="/terms-of-service-agreement">
                    Terms of Service
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="w-full border-t border-gray-300">
          <footer className="my-10 text-center md:text-left">
            <p>
              &copy; {new Date().getFullYear()} Lesson Planner. All rights
              reserved.
            </p>
          </footer>
        </div>
      </div>
    </div>
  );
}

export default Landing;
